.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
/* .loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.4), rgba(0, 0, 0, 0));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.4), rgba(0, 0, 0, 0));
} */
.password .ant-input-outlined {
  border: none;
  background-color: #f3f4fa;
  cursor: pointer;
}

/* .ant-menu-overflow-item .ant-menu-item{

} */
/* :focus{
    color: antiquewhite;
} */
/* .ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected,.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected,.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected{
 color: rgb(68, 84, 111);
 } */
.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
  min-width: 102px;
  font-family: "Open Sans";
}

.Reportsbutton.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-item,
.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  font-family: "Open Sans";
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected::after {
  border-bottom-width: 3px;
  border-bottom-color: #1677ff;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu:hover::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-active::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-active::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-active::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-active::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-open::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-open::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-open::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-open::after {
  border-bottom-width: 3px;
  border-bottom-color: #1677ff;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu {
  margin-right: 7px;
}

.header-ant.ant-layout-header {

  padding: 0px 34px;
}