@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

/* .ant-menu.ant-menu-inline-collapsed {
  width: 50px !important;
  transition: 0.2s;
} */

.ProfileCss{
font-size: 18px;
  font-weight: 600;
  text-align: -webkit-right !important;
}
.SkeletonClass.ant-skeleton
  .ant-skeleton-content
  .ant-skeleton-paragraph
  > li
  + li {
  margin: 20px 0px !important;
  height: 13px !important;
}
.SkeletonClass.ant-skeleton .ant-skeleton-content .ant-skeleton-title {
  height: 12px !important;
}
.SkeletonClass.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li {
  height: 12px !important;
  margin: 17px 0px !important;
}
.fs13-5 {
  font-size: 13.5px !important;
}
.fs13 {
  font-size: 13px;
}
.color-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.text-end {
  text-align: end;
}
.projectTimeLine {
  height: calc(100% - 90px);
  overflow: hidden;
}
.text-center {
  text-align: center !important;
}
.capTask-custom-col.ant-col-xl-17 {
  flex: 0 0 73.833333%;
  max-width: 73.833333%;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .capTask-custom-col.ant-col-xl-17 {
    flex: 0 0 72.833333%;
    max-width: 100%;
  }
}

.o1 {
  order: 1 !important;
}
.o2 {
  order: 2 !important;
}
.o3 {
  order: 3 !important;
}
.o4 {
  order: 4 !important;
}
.o5 {
  order: 5 !important;
}
.o6 {
  order: 6 !important;
}
.o7 {
  order: 7 !important;
}
.dnd_task {
  min-height: 33px;
  overflow: auto;
}

.task_list {
  height: auto;
  width: 100%;
}
.task_gird {
  height: 600px;
  width: 300px;
}
.task_gird_item {
  height: 50px;
  background-color: red;
}
.d-r {
  display: none;
}
.board-rounded-card {
  margin-top: 8px;
  background-color: white;
  padding: 14px 8px 14px 14px;
  border-radius: 12px;
}
.board-rounded-cardd {
  background-color: white;
  padding: -1px 8px 14px 14px;
  border-radius: 12px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}
.ReactVirtualized__Grid__innerScrollContainer {
  width: 100% !important;
  max-width: 100% !important;
}
.ReactVirtualized__List {
  width: 100% !important;
}
.custom-accordion-section {
  background-color: #f2f6fe;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  max-width: 100%;
  border-radius: 8px;
  margin: 10px 4px;
}
.withoutCollapse {
  position: fixed !important;
  z-index: 1 !important;
  overflow: auto !important;
  left: 200px !important;
  background-color: #f3f4fa;
  width: calc(100% - 200px) !important;
  transition: 0.4s !important;
  top: 64px !important;
  padding-top: 4px !important;
}
.collapse {
  position: fixed !important;
  z-index: 1 !important;
  overflow: auto !important;
  left: 50px !important;
  /* background-color:red; */
  width: calc(100% - 50px) !important;
  transition: 0.4s !important;
  top: 64px !important;
  padding-top: 4px !important;
}

.capTask-slider {
  position: fixed !important;
  width: 200px !important;
  box-shadow: 0 0 3px rgb(0 0 0 / 6%) !important;
  height: calc(100% - 70px);
  /* z-index: 10000000 !important; */
}
.capTask-coll-slider {
  position: fixed !important;
  box-shadow: 0 0 3px rgb(0 0 0 / 6%) !important;
  width: 50px !important;
  height: calc(100% - 70px);
  /* z-index: 10000000 !important; */
}
.header {
  padding: 0px;
  background: rgb(255, 255, 255);
  position: fixed;
  width: calc(100% - 200px);
  left: 200px;
  z-index: 1;
  transition: 0.4s;
}
.collHeader {
  padding: 0px;
  position: fixed;
  width: calc(100% - 50px);
  z-index: 1;
  left: 50px;
  transition: 0.4s;
}
.capTask-content-footer {
  position: fixed;
  height: 40px;
  width: calc(100% - 200px);
  background-color: #f3f4fa;
  top: calc(100% - 40px);
  left: 200px;
  transition: 0.4s;
}
.capTask-content-coll-footer {
  position: fixed;
  height: 40px;
  transition: 0.4s;
  width: calc(100% - 50px);
  background-color: #f3f4fa;
  top: calc(100% - 40px);
  left: 50px;
}
.capTask-layout-content {
  position: relative;
  margin: 0px;
  overflow-y: auto;
  background: rgb(243, 244, 250);
  top: 50px;
  left: 200px;
  /* transition: left 0.4s; */
  /* width: calc(100% - 200px); */
  /* overflow: auto; */
}
.capTask-layout-coll-content {
  position: relative;
  margin: 0px;
  overflow-y: auto;
  background: rgb(243, 244, 250);
  top: 50px;
  left: 50px;
  transition: left 0.4s;
  /* width: calc(100% - 50px); */
  /* overflow: auto; */
}
.item {
  padding: 20px;
  border: "2px solid transparent";
  width: 320px;
  height: 60px;
  background-color: #5aa879;
  margin: 20px;
  border-radius: 8px;
  color: "white";
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2 ease,
    background-color 0.2s ease;
  touch-action: none;
}
.slick-prev {
  left: -20px !important;
}
.custom-collapse {
  margin: 8px;
  padding: 4px;
  /* max-height: 500px; */
  /* overflow-y: auto; */
  background-color: #f5f5f5;
  border-radius: 8px;
}
.removeItem {
  text-decoration: line-through !important;
}
/* .ant-list-item-meta {
  margin-block-end: 8px !important;
}  */
.ant-list-item-meta-title {
  font-size: 13px !important;
}
.zeroHeightItem .ant-form-item-control-input {
  min-height: 0px !important;
}
.capTask-select-filter .ant-select-arrow {
  font-size: 12px !important;
  margin-top: -5px !important;
}
.capTask-select-filter .ant-select-selection-item {
  margin-left: 6px !important;
}
.ant-upload-list-item-name {
  cursor: pointer !important;
  color: #1677ff !important;
}
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 44% !important;
  display: block;
  width: 49px !important;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 99 !important;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  cursor: not-allowed !important;
  opacity: 2 !important;
  color: rgb(230, 230, 230) !important;
}
.Board-Backlog .ant-select-selection-item {
  cursor: pointer !important;
}
.slick-next {
  top: 44% !important;
  width: 77px !important;
}
.slick-next:before,
.slick-prev:before {
  font-size: 29px !important;
  color: rgb(55, 125, 255) !important;
}

.ant-table-pagination.ant-pagination {
  margin: 12px 0px 0px 0px !important;
}
.ant-select-clear {
  inset-inline-end: 7px !important;
  font-size: 12px !important;
}
/* .ant-select-tree-switcher {
  width: 4px !important;
} */
.ant-select-selection-placeholder {
  margin: 0px !important;
}
.ant-typography {
  margin-bottom: 0px !important;
  color: inherit !important;
}
.ant-list-item-meta-description {
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px !important;
  line-height: 1.571429;
}
.ant-list-item-meta-avatar {
  margin-inline-end: 8px !important;
}
.ant-list-item-meta-title {
  margin: 0px !important;
}
.custom-skeleton .ant-skeleton {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 6.63px 0px !important;
}

* {
  outline: none !important;
}
.skeletonTable .ant-table {
  line-height: 0.571429px !important;
}
.removeRowBorder .ant-table-tbody > tr > td {
  border-bottom: none !important;
}
.capTask-custom-select .ant-select-selector {
  font-size: 14px !important;
  padding: 0px 8px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

.capTask-custom-select .ant-select-selection-search-input {
  cursor: pointer !important;
}

.issueReport-custom-select .ant-select-selector {
  cursor: pointer !important;
}

.issueReport-custom-select .ant-select-selection-search-input {
  cursor: pointer !important;
}
.capTask-custom-select .ant-select-arrow {
  font-size: 12px !important;
  inset-inline-end: 11px !important;
}
.capTask-custom-select .ant-select-clear {
  font-size: 12px !important;
  inset-inline-end: 11px !important;
  margin-top: -7px !important;
}
.capTask-custom-select .ant-select-selection-search {
  inset-inline-start: 8px !important;
}
.ant-table-wrapper .ant-table-pagination-right {
  justify-content: flex-end !important;
}

.ant-pagination-mini .ant-pagination-options {
  margin-inline-start: 2px;
}

.ant-pagination-options {
  display: block !important;
  left: 0;
  position: absolute;
}

.ant-select-multiple.ant-select-sm {
  font-size: 14px !important;
}

.pb-2 {
  padding-bottom: 8px;
}

.fs9 {
  font-size: 9px !important;
}
.fw500 {
  font-weight: 500 !important;
}
.fs20 {
  font-size: 20px !important;
}
.fs11 {
  font-size: 11px !important;
}
.ant-card-head {
  border-bottom: none !important;
}
.ant-select-multiple.ant-select-sm .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
  border-radius: 4px;
}
.ant-select-selector {
  border-color: #dee2e6 !important;
  box-shadow: none !important;
}
.me {
  margin-right: 12px;
}
.ant-select-multiple
  .ant-select-selection-overflow
  .ant-select-selection-item-remove {
  padding: 10px 5px;
}
.ant-select-borderless.ant-select-multiple .ant-select-selection-item {
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
  margin-right: 4px;
  padding: 1px 5px;
}
.ant-form-item .ant-form-item-explain-error {
  color: #ff4d4f;
}
.borderLessInput:focus {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.ant-select-multiple.ant-select-sm {
  font-size: 14px;
}
.ant-form-item .ant-form-item-label-left {
  text-align: start;
}
.borderLessInput,
.borderLessInput:focus {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.borderLessInput {
  background-color: #fff !important;
  border: none;
  border-bottom: 1px solid #dee2e6;
  outline: none;
  padding: 0;
}
.DrawerPadding {
  padding: 5px 20px;
}
.btn-m-sm {
  padding: 3px 35px !important;
}

.capTask-btn-light {
  background-color: #f8f9fa;
  border: none !important;
  color: #000;
  margin-right: 8px;
}
.ant-drawer-body {
  padding: 0 !important;
}
.ant-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
  scrollbar-width: none;
}

.ant-drawer .ant-drawer-footer {
  flex-shrink: 0;
  padding: 8px 16px;
  border-top: 1px solid rgba(5, 5, 5, 0.06);
}

.ant-drawer-header {
  padding: 8px 16px !important;
}
.customHeader .ant-drawer-header {
  padding: 0px !important;
}
.ant-drawer .ant-drawer-header {
  border-bottom: none;
}
.capTask-delete-btn,
.capTask-edit-btn {
  border-radius: 4px;
  font-size: 15px !important;
  margin: 0 4px;
  padding: 2px;
}
.capTask-delete-btn {
  color: #d31212;
}
.capTask-edit-btn {
  color: #004085;
}

.capTask-custom-table .ant-table-container table thead tr th {
  border-top: 1px solid rgba(5, 5, 5, 0.2) !important;
  font-weight: 600;
  opacity: 90%;
}
.project-table .ant-table-cell {
  padding: 2px 7px !important;
}
.project-Create .ant-table-cell {
  padding: 4px 7px !important;
}

.btn-clr-primary:hover {
  border-color: #d3e3ff !important;
  background: #f3f4fa !important;
  color: #377dff !important;
  opacity: 0.8 !important;
}

.mb-1 {
  margin-bottom: 4px;
}
.mt-3 {
  margin-top: 12px;
}

.btn-primary-md {
  font-weight: 500;
  height: 24px !important;
  padding: 2px 10px !important;
}

.btn-clr-primary {
  background: #f3f4fa;
  border-color: #d3e3ff;
  color: #377dff;
}

.capTask-btn {
  align-items: center;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  line-height: 1;
}

.pe-2 {
  padding-right: 8px;
}

.BreadcrumbLink {
  color: #377dff !important;
}

.fs12 {
  font-size: 12px;
}

.fw600 {
  font-weight: 600 !important;
}

/* .opacity50 {
  opacity: 0.5;
} */

.d-block {
  display: block;
}

.ms-3 {
  margin-left: 16px !important;
}

.settingsIcon {
  color: #4285f4 !important;
  font-size: 18px !important;
  height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.py10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.text-dark {
  color: #172b4d;
}

.cursor {
  cursor: pointer;
}

.w100 {
  width: 100%;
}

.px-4 {
  padding-left: 24px;
  padding-right: 24px;
}

/* .headingSettings {
  background-color: #e3e8ed38;
  padding: 9px 24px;
} */

.mb-4 {
  margin-bottom: 24px;
}

/* .roundedCornerMedium {
  border-radius: 10px;
} */

.bg-white {
  background-color: #fff;
}

.shadowLight {
  box-shadow: 0 0 12px rgba(214, 223, 245, 0.25);
}

.ant-btn.ant-btn-sm {
  font-size: 14px;
  height: 24px;
  padding: 0px 7px;
  border-radius: 4px;
}

.py-2 {
  padding-bottom: 8px;
  padding-top: 8px;
}

.sideLogo {
  padding: 5px 25px;
  cursor: pointer;
  transition: 0.4s;
}

.collSideLogo {
  padding: 16px 6px;
  cursor: pointer;
  width: 100% !important;
  transition: 0.4s;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.ant-layout .ant-layout-sider-children {
  box-shadow: 0 0 3px rgb(0 0 0 / 6%) !important;
}

.sidebar .ant-menu-item-selected {
  background: #f3f4fa !important;
  border-left: 3px solid #377dff !important;
  color: #377dff !important;
  font-weight: 600 !important;
}

.sidebar .ant-menu-inline .ant-menu-item {
  margin-inline: 0px !important;
}

.sidebar {
  margin-top: 10px;
}

.userAvatar {
  background-color: #f4f8ff;
  border: 2px solid #d1e6ff;
  cursor: pointer;
  font-size: 16px !important;
  color: #8ec1ff;
  margin: 14px;
  margin-top: 9px;
}

.dashboardCard {
  height: 200px;
  min-width: 300px;
  margin: 10px 10px;
}

.scrollContainer {
  display: flex;
  width: 100%;
  background-color: #f3f4fa;
  padding-bottom: 2px;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none;
}

/* .projectListRow {
  min-height: 200px;
} */

.ant-spin.projectListSpin {
  top: 90px !important;
  z-index: 0 !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.activiyLogCard {
  height: auto;
  /* width: 280px; */
  margin: 0px;
}

:where(.css-dev-only-do-not-override-mfhgkv).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-mfhgkv).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  border-top: none;
  border-bottom: none;
}

.ant-table-wrapper .ant-table-tbody {
  border-bottom: "none" !important;
}

.innerLogCard {
  height: 100px;
  /* margin: 16px 0px; */
}

.ant-table-wrapper .ant-table-tbody {
  border-top: none !important;
}

.innerLogCard .ant-card-body {
  padding: 5px !important;
}

.activiyLogsList {
  height: 600px;
  overflow-y: auto;
}

/* Editor css */
.se-btn-tray {
  padding: 5px !important;
  background-color: white !important;
}

.se-toolbar {
  outline: 1px solid #ccced1 !important;
}

.se-btn-module-border {
  border: 0px solid #ccced1 !important;
  padding: 0px !important;
  border-radius: 0px !important;
}

.se-btn {
  /* float: left; */
  width: 33px !important;
  /* height: 33px !important; */
  /* border: 0; */
  /* border-radius: 4px; */
  margin: 1px !important;
  padding: 0px !important;
  /* font-size: 12px; */
  line-height: 34px !important;
}

.sun-editor button > svg {
  width: 19px;
  /* height: 20px; */
  /* margin: auto; */
  fill: #000000b0 !important;
  /* display: block; */
  /* text-align: center; */
  /* float: none; */
}

.se-btn-select .txt {
  flex: auto;
  text-align: left;
  padding-left: 10px !important;
}

.se-btn-select.se-btn-tool-font {
  width: 100px !important;
  border: 0px solid #ccced1 !important;
  padding-right: 5px !important;
}

.se-btn-select.se-btn-tool-size {
  width: 78px !important;
  padding-right: 5px !important;
  border: 0px solid #ccced1 !important;
}

/* hover */
.se-tooltip:hover {
  /* height: 20px; */
  /* margin: auto; */
  border-radius: 0px !important;
  fill: red !important;
  border-radius: 0px !important;
  background-color: #d1d1d144 !important;
  /* display: block; */
  /* text-align: center; */
  /* float: none; */
}

/* active */
/* .se-btn:enabled.active>svg{
    border-radius: 0px !important;
    fill: #2977ff !important;
    
} */

/* select */

.se-list-layer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 5;
  border: 0px solid #bababa !important;
  border-radius: 4px;
  padding: 6px 0;
  background-color: #fff;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.5) !important;
  outline: 0 none;
}

.ant-menu-item::after {
  width: 100px !important;
  margin: 0px 12px !important;
}

/* .Projectscss{
     background: linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px); 
} */
/* .custom-button{
    opacity: 1;
    font-weight: 500;
    font-family: Open Sans;
    border: none;
    font-size: 15px;
    text-align: center;
} */

.customselect.ant-select-show-search:where(
    .css-dev-only-do-not-override-mfhgkv
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  /* border-radius: '0' !important;
    border: '0' !important; */
  border-radius: 0 !important;
}

.hoveritems:hover {
  box-shadow: 0 0 4px 0px rgba(145, 169, 217, 0.56);
}

.itemshover:hover {
  box-shadow: 0 0 12px 4px rgba(145, 169, 217, 0.56);
}
.BacklogBoard:hover {
  box-shadow: 0 0 12px 4px rgba(145, 169, 217, 0.56);
}

.hoveritems {
  height: 35px;
  background-color: white;
  cursor: grab;
}

.Moveicon {
  opacity: 0.8;
}

.Moveicon:hover {
  opacity: 1;
}

.customselect .ant-select-open {
  padding-bottom: 0px !important;
  width: 150px !important;
  scrollbar-width: "none";
}

.customselect.ant-select-show-search:where(
    .css-dev-only-do-not-override-ajavm2
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: rgb(242, 246, 252);
  border-radius: 0;
  /* width: 120px; */
}

.ant-select-dropdown
  .css-dev-only-do-not-override-mfhgkv
  .ant-select-dropdown-placement-topLeft {
  padding-bottom: 0px !important;
  width: 150px !important;
  scrollbar-width: "none";
}

.itemshover .ant-card-body {
  padding: 12px !important;
  /* padding-bottom: 0px !important; */
}

.badgecolor .ant-scroll-number-only-unit {
  color: rgb(103, 126, 219) !important;
}

/* border: 0 !important;
    }
  
    .dataFormet {
      font-size: 14px;
      font-family: 'Open Sans';
      font-weight: 500;
      color: rgb(136, 135, 135);
    } */
.toothbarselect.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0 !important;
  border: 0 !important;
  height: 35px;
}

.toothbarselect.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
  cursor: pointer;
}

.taskbordselect.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0 !important;
  border: 0 !important;
  height: 35px;
  background-color: #f3f4fa;
}

.taskbordselectview.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0 !important;
  border: 0 !important;
  height: 35px;
}

.dataFormet {
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 500;
  color: rgb(136, 135, 135);
}

.toothbarinput.ant-input-affix-wrapper {
  border-radius: 0 !important;
  border: 0 !important;
  height: 34px;
  cursor: pointer;
}

.toothbarinput.ant-input-affix-wrapper:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
}

.toothbarRange.ant-picker-outlined {
  border-radius: 0 !important;
  border: 0 !important;
  height: 34px;
}

.toothbarRange.ant-picker-outlined:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
  cursor: pointer;
}

.rc-virtual-list-scrollbar-vertical {
  position: absolute;
  right: 0px;
  visibility: hidden;
}

/* .rc-virtual-list-holder{
  height: 137px !important;
} */

/* Tree Select scrollbar none */
.ant-select-tree-list-scrollbar-vertical {
  position: absolute;
  visibility: hidden;
}

.sidebarcss {
  /* margin-top: 4px; */
  /* margin-left: 10px; */
  background-color: rgb(228, 248, 241);
  border-radius: 50%;
  height: 24px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #38d79e;
}

.taskbord.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
  background-color: #f4f5f7;
  height: 20px;
}

.modelpendingtask .ant-modal-content {
  height: 600px;
  overflow-y: "scroll";
  scrollbar-width: none;
}

.tabelbordertaskbord {
  border-left: 5px solid #ecf1fb;
}

/* Task Board css */
.tabelbordertaskbordd {
  border: 1px solid #ecf1fb;
  border-bottom: none;
  border-radius: 7px;
  height: 580px;
}

.pandingtabel {
  border: 1px solid #ecf1fb;
  border-bottom: none;
  border-radius: 7px;
  height: 210px;
  scrollbar-width: none;
}

.activiyLogCard:where(.css-dev-only-do-not-override-ajavm2).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-ajavm2).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  border-bottom: none;
}

/* Card  */
.main-border .ant-card-head {
  border-bottom: none;
}

.main-border .ant-card-body {
  padding-top: 0px;
  padding-bottom: 2px;
}

.main-border.ant-card-bordered {
  background-color: var(--ds-surface-overlay, #fff);
  border-radius: 8px;
  border: 1px solid rgb(243, 245, 249);
}

/* login bord */
:where(.css-dev-only-do-not-override-1e4ifg2).ant-card
  .ant-card-meta-detail
  > div:not(:last-child) {
  margin: 0;
}

.Menuborder.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light {
  border-inline-end: none;
}

.activiyLogCard.ant-card .ant-card-body {
  padding: 5px;
  /* border-radius: 0 0 8px 8px; */
}
.main-border.ant-card .ant-card-body {
  padding: 12px;
  /* border-radius: 0 0 8px 8px; */
}
.Card-refresh.ant-card .ant-card-body {
  padding: 10px;
  /* border-radius: 0 0 8px 8px; */
}
.ant-card .ant-card-body {
  padding: 0px;
  /* border-radius: 0 0 8px 8px; */
}
/* **** */
.avatarimg {
  border: 2px dashed var(--ds-border, #d0d6d0);
  border-radius: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: border-color 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.meeting.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
}

.meeting.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
  cursor: pointer;
}

.custom-range-picker.ant-picker-outlined {
  border: none;
}

.custom-range-picker.ant-picker-outlined:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
  cursor: pointer;
}

/* .Assign-Checkboxa
  :where(.css-dev-only-do-not-override-ajavm2).ant-checkbox
  + span {
  padding-inline-end: 4px;
} */
/* login bord */
.mainuserr.ant-card-meta-detail {
  margin-bottom: 0px;
}

:where(.css-1njxnwb).ant-card .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0px;
}

:where(.css-1njxnwb).ant-card .ant-card-body {
  padding: 14px;
}

.uploadimg.ant-tooltip {
  display: none;
}

/* Sidebar  */
.sidbar.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0;
  border: none;
  background-color: rgb(242, 246, 252);
  cursor: pointer;
  height: 34px;
}

.sidbarr.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0;
  border: none;
  background-color: rgb(242, 246, 252);
  cursor: pointer;
  height: 34px;
}

.sidbarr.ant-select-multiple
  .ant-select-selection-overflow
  .ant-select-selection-item-remove {
  display: none;
  background-color: none;
}

/* Sidebar css */
.Titlecss {
  height: 49px;
  border: none;
}

.Titlecss:hover {
  background-color: rgb(244, 245, 247);
  border-radius: 3px;
}

.Detailsselect.ant-flex-align-stretch {
  margin: 0px -22px;
}

/* Dashbordermain css */
.Titlecsss {
  height: 40px;
  border: none;
}

/* .ant-card-small > .ant-card-body {
  padding-left: 7;
} */

/* Schedule Meeting css */
.meetingselect {
  height: 37px;
  border-radius: 0;
}

.meetingselect.ant-select-show-search.ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
  cursor: pointer;
}

.meetinginput {
  height: 37px;
  /* border-radius: 0; */
}

.meetinginput:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
  cursor: pointer;
}

.meetingdatepicker:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
}

.meetingdatepicker {
  height: 37px;
  /* border-radius: 0; */
}

.meetingTimePicker:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
}

.meetingTimePicker {
  height: 37px;
  /* border-radius: 0; */
}

.meetingSelect.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
  cursor: pointer;
}

.meetingSelect {
  height: 37px;
  /* border-radius: 0; */
}

.meetingSelect.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  /* border: none; */
  border-color: var(--ds-border-input, #ebecf0);
  cursor: pointer;
}

.ant-form-item {
  margin-bottom: 15px;
}

/* Sun */
.Commentsun {
  height: 60px;
  border: none;
  background-color: rgba(244, 245, 247, 0.5);
  border-radius: 1px;
}

/* ToothabarReport css */
.Reportinput:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
}

.commonstyle.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0 !important;
  border: 0 !important;
  height: 33px;
  cursor: pointer;
}

.commonstyle.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
}

/*  */
.treesekect.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: var(--ds-border-input, #ebecf0);
  height: 35px;
  cursor: pointer;
  border-radius: 0;
}

.treesekect:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
}

.Watchers.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  background-color: var(--ds-background-input-hovered, rgb(228, 228, 228));
}

.Watchers.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: var(--ds-background-input-hovered, #ebecf075);
  height: 37px;
}

.activiyLogCard.ant-card-small > .ant-card-body {
  padding-bottom: 0;
  padding-top: 2px;
}

/*UploadAvatar.tsx css */
.uploadimg.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item {
  width: 151px;
  border: none;
  height: auto;
  margin: -18px -13px;
}

.uploadimg.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 151px;
  height: 152px;
  margin: -18px -13px;
}

.modelupload.ant-modal .ant-modal-close {
  display: none;
}

/* NOT Found Button Code 404 */

.custom-buttonnn {
  background-color: #007bff;
  /* Light blue background */
  border-radius: 100px;
  box-shadow: rgba(0, 113, 188, 0.9) 0 -25px 18px -14px inset,
    rgba(0, 113, 188, 0.2) 0 1px 2px, rgba(0, 113, 188, 0.2) 0 2px 4px,
    rgba(0, 113, 188, 0.2) 0 4px 8px, rgba(0, 113, 188, 0.2) 0 8px 16px,
    rgba(0, 113, 188, 0.2) 0 16px 32px;
  color: #ffffff;
  /* White text color */
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.custom-buttonnn:hover {
  background-color: #357abd;
  /* Darker blue on hover */
  box-shadow: rgba(0, 113, 188, 0.6) 0 -25px 18px -14px inset,
    rgba(0, 113, 188, 0.3) 0 1px 2px, rgba(0, 113, 188, 0.3) 0 2px 4px,
    rgba(0, 113, 188, 0.3) 0 4px 8px, rgba(0, 113, 188, 0.3) 0 8px 16px,
    rgba(0, 113, 188, 0.3) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}

.custom-buttonnn:focus {
  outline: none;
  /* Removes default focus outline */
  box-shadow: 0 0 0 3px rgba(0, 113, 188, 0.3);
  /* Adds a blue outline */
}
.cardbuttom.ant-card .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0;
}
/* NOT Found Button Code 404 */

/* Meeting css */
.ant-table-wrapper .ant-table-pagination-right {
  justify-content: start;
}

.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: none;
}

.ant-picker-outlined:hover {
  border-color: none;
  background-color: #ffffff;
}

.ant-popover .ant-popover-content {
  position: relative;
  margin-top: 7px;
  margin-right: 10px;
}

/* MainLayuot */
.ant-menu .ant-menu-submenu-expand-icon,
.ant-menu .ant-menu-submenu-arrow {
  margin: 4px 70px;
}
/* profile css */
.hoverprofile:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
  border-radius: 4px;
}
.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #d9d9d9;
  background: #ffffff;
}
.ant-table-wrapper .ant-table-column-sorter-up,
.ant-table-wrapper .ant-table-column-sorter-down {
  font-size: 9px;
}
.issuestabel.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 1px 14px;
}
.issues.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 3px 14px !important;
}
.dash-link.ant-btn {
  font-size: 13px;
  line-height: 1.6153846153846154;
  height: 0px;
  padding: 4.5px 15px;
  border-radius: 6px;
}

.Activitycss.ant-table-cell.ant-table-wrapper,
.ant-table-wrapper,
.ant-table-wrapper tfoot > tr > th,
.ant-table-wrapper tfoot > tr > td {
  position: relative;
  padding: 0px 0px;
  overflow-wrap: break-word;
}
.resume-btn-sm {
  box-shadow: none !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  height: 20px !important;
  padding: 1px 12px !important;
}
.Tabe-Description.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav {
  margin: 0 0 16px 22px !important;
}
.ant-tabs .ant-tabs-tab {
  padding: 0px 0px !important;
}
.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 6px 2px 18px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  margin: 0px !important;
}
.ant-select .ant-select-arrow {
  inset-inline-end: 5px;
  margin-top: -5px;
}

.roundedCornerSmall {
  border-radius: 4px;
}
.ant-table-body {
  overflow-y: scroll;
  max-height: 485px;
  scrollbar-width: "none";
}

/* Hide scrollbar  */

/* html {
  scrollbar-width: none;
} */

/* .ant-row {
  scrollbar-width: none;
} */
.ant-table-body {
  scrollbar-width: none;
}
.ant-table-wrapper .ant-table {
  border-radius: 5px;
}
.ant-card-small > .ant-card-head {
  padding: 0 8px !important;
}
/* .ant-btn {

    height: 0px; 
    padding: 0px 0px;
  
} */

.ant-popover .ant-popover-inner {
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 12px;
}
.circle-round-small {
  border: 2px solid;
  border-radius: 50%;
  height: 9px;
  width: 9px;
}
.dash-link:hover {
  color: #007bff !important;
}

.rotate-360 {
  margin-top: 10px;
  display: inline-block;
  transform: rotate(45deg);
  transition: transform 1s ease-in-out;
}
.treeselectadduser.ant-select-outlined.ant-select-multiple
  .ant-select-selection-item {
  border-radius: 8 !important;
  margin: 2px 4px !important;
}

/* collapse code  */
.ant-collapse .ant-collapse-content {
  background-color: #f5f5f5;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 8px !important;
}
.ant-collapse-header {
  padding: 4px 12px !important;
}

.CollapseIconHide.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  display: none;
}

.CollapseSetting .ant-collapse-header {
  margin: 8px 0px !important;
}
.capTask-custom-table
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0px 7px !important;
}
.capTask-custom-item
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0px 7px !important;
}
.capTask-custom-table
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 24px !important;
}
.ant-select-single .ant-select-selector {
  border-radius: 4px !important;
}
.capTask-custom-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 100% !important;
}
.ant-spin-nested-loading > div > .ant-spin {
  top: 170px !important;
  width: 83% !important;
}

::-webkit-scrollbar {
  height: 8px !important;
  width: 3px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #ececec !important;
}

.handleViewModeChange.ant-btn {
  line-height: 1.6153846153846154;
  height: 27px;
  padding: 5.5px 7px;
  border-radius: 0;
}

/* Login page   */
.custom-font-size {
  font-size: 14px; /* Default font size */
}

@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 1640px) and (max-width: 1864px) {
  .custom-font-size {
    font-size: 11px; /* Set font size to 11px for the specified ranges */
  }
}

.CloseOutlinedIcon:hover {
  color: #377dff;
}

.CloseOutlinedIcon {
  color: #ff879d;
}
.ant-btn.ant-btn-icon-only .anticon {
  font-size: 13px;
}
.ant-segmented .ant-segmented-item-selected {
  color: rgba(0, 0, 0, 0.88);
  height: 27px !important;
  background: #f3f4fa !important;
  color: #377dff !important;
  border-color: #d3e3ff !important;
}
.ant-segmented {
  line-height: 27px !important;
}
.ant-segmented {
  padding: 0px !important;
  font-size: 14px !important;
  border-radius: 3px !important;
}
.custom-badge {
  background: #4096ff;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: 400;
  height: 17px;
  line-height: 15px;
  margin-left: 4px;
  padding: 0 8px;
}

.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
  background-color: rgb(237, 241, 249, 1);
}
@media (min-width: 1764px) and (max-width: 1600px),
  (min-width: 1764px) and (max-width: 1864px) {
  .capTask-custom-select {
    width: 150px !important;
  }

  .custom-input {
    width: 160px !important;
  }

  .custom-button {
    margin: 5px !important;
  }

  /* .custom-segmented {
    width: 120px !important;
  } */
}

/* In your CSS file or styled component */
@media (min-width: 768) and (max-width: 768) {
  .peoples-font {
    font-size: 18px;
    /* Set font size to 11px for the specified ranges */
  }
}
@media (min-width: 1024) and (max-width: 1100) {
  .Item1 {
    margin: 0px !important;
    width: 25%;
  }
}
.peoples-font-size {
  font-size: 11.5px; /* Default font size */
}

@media (min-width: 1024px) and (max-width: 1024px),
  (min-width: 1100px) and (max-width: 1900px) {
  .peoples-font-size {
    font-size: 10px; /* Set font size to 11px for the specified ranges */
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  .peoples-Card {
    display: none; /* Set font size to 11px for the specified ranges */
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  .peoples-Table {
    width: 100%;
  }
}
@media (min-width: 1886px) and (max-width: 1492px) {
  .slick-arrow {
    top: 44% !important;
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1580px) {
  .ResponsiveClass {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 984px) {
  .ResponsiveClass {
    display: flex;
  }
}
@media (min-width: 768px) and (max-width: 1682px) {
  .priorityHide {
    display: none;
  }
}

/* @media(min-width:1508px) and (max-width: 1900px) {
  .slick-arrow {
    top: 44% !important;
  }
} */
/* @media(min-width:1200px) and (max-width: 1500px) {
  .slick-arrow {
    top: 44% !important;
  }
} */
@media (min-width: 1010px) and (max-width: 1198px) {
  .slick-arrow {
    top: 44% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .slick-arrow {
    top: 44% !important;
    /* width: 55px !important; */
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .ResponsiveClass {
    margin-right: 0px !important;
    margin-left: -9px !important;
    width: 5px !important;
  }
}
@media (min-width: 768px) and (max-width: 768px) {
  .HideBoard {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .descriptionHide {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .ant-card-small > .ant-card-head {
    display: none;
    min-height: 0px;
  }
}

.btn-clr-primary {
  background: #f3f4fa;
  border-color: #d3e3ff;
  color: #377dff;
}

@media (min-width: 768px) and (max-width: 768px) {
  .ToothierHide {
    display: none;
  }
}
@media (min-width: 1000px) and (max-width: 1734px) {
  .PriorityIconRemove {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 800px) {
  .PriorityRemove {
    display: none;
  }
}
@media (min-width: 1600px) and (max-width: 1800px) {
  .CardWidthSet {
    margin: 10px 4px !important;
  }
}
@media (min-width: 1501px) and (max-width: 1857px) {
  .main-border {
    height: 80px;
  }
}
@media (min-width: 1024px) and (max-width: 1100px) {
  .ResponsiveHide {
    height: "100%";
  }
}

@media (min-width: 1026px) and (max-width: 1500px) {
  .Resposivecostom3 {
    padding: 0px;
  }
}
@media (min-width: 1026px) and (max-width: 1500px) {
  .main-border.ant-card .ant-card-body {
    padding: 18px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .HideHand {
    display: none;
  }
}
@media (min-width: 1700px) and (max-width: 1866px) {
  .SelectTaskBoard {
    width: 110% !important;
  }
}
@media (min-width: 768px) and (max-width: 1792px) {
  .Grid-view {
    display: none;
  }
}

.ant-avatar-string {
  font-size: 13px;
}
.Card-Width-one.slick-slider .slick-track {
  margin: 0 !important;
}
/* .cursorColor:hover{

} */
.profileAvatar {
  background-color: #f4f8ff;
  border: 2px solid #d1e6ff;
  cursor: pointer;
  font-size: 16px !important;
  color: #8ec1ff;
  margin: 14px;
  margin-top: 9px;
  margin: 60px 370px;
}
.avatarWrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.cameraIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: white;
  opacity: 0;
  transition: opacity 0s ease;
}

.avatarWrapper:hover .cameraIcon {
  opacity: 1;
}
.Account:hover {
  background-color: #5d6163;
}
.uploadImg.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item {
  width: 814px;
}
.ant-select-borderless.ant-select-status-error .ant-select-selection-item {
  color: #0a0a0a;
}
/* .ant-table-tbody>tr.ant-table-placeholder:hover>th, .ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder:hover>td, .ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder {
  background: #ffffff;
  height: 52vh;
} */

.ant-form-item-explain-error {
  font-size: 13px;
  height: 16px;
}

/* .ant-tree-select-dropdown .ant-select-tree .ant-select-tree-switcher {
  margin-inline-end: 13px !important;
  margin-inline-start: 5px !important;
}
 */



 /* Profile Send feedback Css */
.feedback {
  display: inline-block;
  width: 180px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #c6c6cc;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in;
  z-index: 1;
 }
 
 .feedback::before,
 .feedback::after {
  content: '';
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  transform: skew(15deg);
  transition: all 0.5s;
  overflow: hidden;
  z-index: -1;
 }
 
 .feedback::before {
  left: -10px;
  background: #240046;
 }
 
 .feedback::after {
  right: -10px;
  background: #5a189a;
 }
 
 .feedback:hover::before,
 .feedback:hover::after {
  width: 58%;
 }
 
 .feedback:hover span {
  color: #e0aaff;
  transition: 0.3s;
 }
 
 .feedback span {
  color: #000;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s ease-in;
 }

.ant-card .ant-card-head{
  min-height: 25px !important;
  margin: 4px !important;
}

@media (min-width: 1200px) and (max-width: 1886px) {
  .CardResponsive {
 width: "500px"!important;
  }
}

.custom-icon {
  background-color: #b3afaf59;
  border-radius: 50%;
  padding: 7px;
  color: #0d539582;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-icon:hover {
  background-color: #ccc;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.custom-iconn {
  border-radius: 50%;
  color: #0d539582;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.NotFound-button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 150px;
  height: 50px;
  background-image: linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%);
  border-radius: 30px;
  border: 1px solid #8F9092;
  transition: all 0.2s ease;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
}

.NotFound-button:hover {
  box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 3px 3px #CECFD1;
}

.NotFound-button:active {
  box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;
}

.NotFound-button:focus {
  box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;
}

.NotFound.ant-result .ant-result-extra {
  text-align: -webkit-center !important;
}

.ProjectNameColor:hover{
 color: #004085;
}
.flexContainer {
  display: flex
;
  height: 26px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  background: transparent;
  color: rgb(50, 49, 48);
}