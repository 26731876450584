.inHandHover:hover {
  font-weight: 700;
}
.inHandHover {
  padding: 3px 6px;
  margin: 0px;
  color: rgb(87, 112, 217);
  line-height: 1.8;
}

/* .ant-select-selector {
  border-radius: 0 !important;
  border: none !important;
} */
.handsearch.ant-input:placeholder-shown {
  border-radius: 0 !important;
  border: none;
  height: 32px;
}
.handsearch.ant-input:placeholder-shown:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  cursor: pointer;
  border-color: var(--ds-border-input, #ebecf0);
  border-radius: 0 !important;
}
.handsearchh.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  background-color: var(--ds-background-input-hovered, #ebecf0);
  border-color: var(--ds-border-input, #ebecf0);
  cursor: pointer;
  border-radius: 0 !important;
  border: none;
}
.handsearchh.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
}
