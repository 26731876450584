body{
  margin: 0px;
}

.ant-table-body::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #00000030;
}

::-webkit-scrollbar-thumb {
  background-color: #00000050;
}

.ant-table-body::-webkit-scrollbar-track {
  background-color: initial;
}
.sidebar .ant-menu-item {
  border-left: 3px solid #fff !important;
  border-radius: 0 !important;
  margin: 0 0 10px !important;
}

.sidebar .anticon {
  color: #4285f4 !important;
  font-size: 20px !important;
  height: 100% !important;
}

.ant-menu-title-content {
  margin-left: 16px !important;
}

.ant-menu-inline-collapsed .ant-menu-item {
  padding-inline: calc(50% - 15px) !important;
}
.sidebar .ant-menu-item {
  border-left: 3px solid #fff !important;
  border-radius: 0 !important;
  margin: 0 0 10px !important;
}