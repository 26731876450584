.forgotButton {
  text-decoration: none;
  font-size: 14px;
  color: #253148;
  display: flex;
  align-items: center;
  line-height: 14px;
  font-family: "Open Sans";
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .rrr {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .rrr {
    background-position: center;

    height: 828px;
  }
}

@media screen and (min-width: 1024px) {
  .rrr {
    background-position: center;
  }
}
/* .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
} */

/* .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    background: radial-gradient(rgba(20, 20, 20, .4), rgba(0, 0, 0, .0));
    background: -webkit-radial-gradient(rgba(20, 20, 20, .4), rgba(0, 0, 0, .0));
}

.resetpass .ant-form-item .ant-form-item-explain-erro {
    text-align: justify;
}
body {
    font-family: "open sans", roboto, arial, sans-seif;
    background: #fff;
}

input {
    background: #fff;
}


.input-box.active-grey .input-label {
    color: #80868b;
    top: -8px;
    background: #fff;
    font-size: 11px;
    transition: 1ms;
}

.input-box.active-grey .input-label svg {
    position: relative;
    width: 11px;
    height: 11px;
    top: 2px;
    transition: 1ms;
}

.input-box {
    position: relative;
    margin: 10px 0;
}

.input-box .input-label {
    position: absolute;
    color: #80868b;
    font-size: 16px;
    font-weight: 400;
    max-width: calc(100% - (2 * 8px));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    left: 8px;
    top: 13px;
    padding: 0 8px;
    transition: 1ms;
    user-select: none;
    pointer-events: none;
}

.input-box .input-label svg {
    position: relative;
    width: 15px;
    height: 15px;
    top: 2px;
    transition: 1ms;
}

.input-box .input-1 {
    width: 99%;
    box-sizing: border-box;
    height: 50px;
    border-radius: 10px;
    color: rgb(32, 33, 36);
    border: 1px solid rgb(185, 196, 214);
    padding: 13px 15px;
    transition: all 1ms ease 0s;
}

.input-box .input-1:focus {
    outline: none;
    border: 2px solid #1a73e8;
    transition: 1ms;
}

.input-box.error .input-label {
    color: #f44336;
    top: -8px;
    background: #fff;
    font-size: 11px;
    transition: 1ms;
}

.input-box.error .input-1 {
    border: 2px solid #f44336;
}

.input-box.focus .input-label,
.input-box.active .input-label {
    color: #1a73e8;
    top: -8px;
    background: #fff;
    font-size: 11px;
    transition: 1ms;
}

.input-box.focus .input-label svg,
.input-box.active .input-label svg {
    position: relative;
    width: 11px;
    height: 11px;
    top: 2px;
    transition: 1ms;
}

.input-box.active .input-1 {
    border: 1px solid #1a73e8;
}

.btn {
    background: #fff;
    color: #333;
    cursor: pointer;
    border: none;
    white-space: normal;
    letter-spacing: 0.25px;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 4px;
    line-height: 20px;
    min-width: 88px;
    transition: 1ms;
}

.btn:hover {
    background: #ddd;
    transition: 1ms;
}

.btn:focus {
    outline: none;
}

.btn-primary {
    background: #1a73e8;
    color: #fff;
    transition: 1ms;
}

.btn-primary:hover {
    background: #287ae6;
    box-shadow: 0 1px 1px 0 rgba(66, 133, 244, 0.45), 0 1px 3px 1px rgba(66, 133, 244, 0.3);
    transition: 1ms;
}

.pull-right {
    float: right;
}

.clear {
    clear: both;
} */
